(function () {
  'use strict';

  class Resources {
    constructor(ResourcesRest, $cookies, $rootScope) {
      this.ResourcesRest = ResourcesRest;
      this.$cookies = $cookies;
      this.$rootScope = $rootScope;
    }

    resourcesIndex(resources = {}, callback = angular.noop) {
      return this.ResourcesRest.resources().get(resources,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    resourcesCreate(resources = {}, callback = angular.noop) {
      return this.ResourcesRest.resources().save({resource: resources},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    myResources(resource = {}, callback = angular.noop) {
      return this.ResourcesRest.myResources().get(resource,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    resourceView(resource = {}, callback = angular.noop) {
      return this.ResourcesRest.resource().get(resource,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    resourceClone(resource = {}, callback = angular.noop) {
      resource.clone = 'clone';
      return this.ResourcesRest.resource().save({resource: resource},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    resourceEdit(resource = {}, callback = angular.noop) {
      return this.ResourcesRest.resource().update({resource: resource},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    resourceDelete(resource = {}, callback = angular.noop) {
      return this.ResourcesRest.resource().remove(resource,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    pollsCreate(polls = {}, callback = angular.noop) {
      return this.ResourcesRest.polls().save(polls,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    pollsUpdate(polls = {}, callback = angular.noop) {
      return this.ResourcesRest.polls().update(polls,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    questionCreate(question = {}, callback = angular.noop) {
      if (!question.is_free_text) {
        question.options = 'options';
      }
      return this.ResourcesRest.polls().save(question,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    questionUpdate(question = {}, callback = angular.noop) {
      if (!question.is_free_text) {
        question.options = 'options';
      }
      return this.ResourcesRest.polls().update(question,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    questionRemove(question = {}, callback = angular.noop) {
      if (!question.is_free_text) {
        question.options = 'options';
      }
      return this.ResourcesRest.polls().remove(question,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    lomMetadata(lomMetadata = {}, callback = angular.noop) {
      return this.ResourcesRest.lomMetadata().get(lomMetadata,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
  }

  /**
   * @ngdoc service
   * @name components.service:Resources
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('Resources', Resources);
}());
